import type { AuthErrorPayload } from '@nhost/nhost-js';

export default function (isElite: boolean) {
  const nhost = useNhost();

  const { t } = useI18n();

  const localePath = useLocalePath();

  const resetPasswordError = ref('');

  const email = ref('');

  const host = useHost();

  function updateEmail() {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.userPasswordResetEmail) {
      const newEmail: string = window.userPasswordResetEmail;
      delete window.userPasswordResetEmail;
      email.value = newEmail;
    }

    const newEmail = new URLSearchParams(window.location.search).get('email');

    if (newEmail) {
      email.value = newEmail;
    }
  }

  function isAuthError(e: unknown): e is AuthErrorPayload {
    return (
      typeof e === 'object' && e !== null && 'message' in e && 'status' in e
    );
  }

  async function resetPassword() {
    try {
      resetPasswordError.value = '';
      const redirectionPath =
        host +
        localePath(
          `/${isElite ? t('routes.elite') : t('routes.account')}/${t(
            'routes.resetPassword'
          )}`
        ) +
        '?email=' +
        email.value;
      let result = await nhost.auth.resetPassword({
        email: email.value,
        options: {
          redirectTo: redirectionPath
        }
      });
      if (result.error) {
        console.error(result.error);
        console.error(redirectionPath);
        // retry without redirection
        result = await nhost.auth.resetPassword({
          email: email.value
        });
      }
      if (result.error) {
        throw result.error;
      }
      return true;
    } catch (e) {
      console.log('Reset password error', e);
      if (isAuthError(e)) {
        resetPasswordError.value = t(
          `auth.resetPasswordErrors.${e.status}`,
          null,
          t('auth.resetPasswordErrors.unknown')
        );
      } else {
        resetPasswordError.value = t('auth.resetPasswordErrors.unknown');
      }
    }
    return false;
  }

  const emailIsValid = computed(() => {
    return (
      email.value.length > 0 &&
      email.value.includes('@') &&
      email.value.includes('.')
    );
  });

  return {
    updateEmail,
    resetPassword,
    resetPasswordError,
    email,
    emailIsValid
  };
}
